import React from "react";
import { graphql, Link } from "gatsby";
import Page from "~/models/page";
import SEO from "~/views/components/generic/seo";
import structuredData from "~/helpers/structured-data";
import Frame from "~/views/layout/frame";
import Header from "~/views/compositions/navigation/header";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import { StaticImage } from "gatsby-plugin-image";

type Props = {
  data: Queries.MediaGuidePageQuery;
};

const MediaGuidePage = ({ data }: Props) => {
  return (
    <Frame>
      <Header />

      <div className="overflow-hidden relative mb-14 lg:mb-20">
        <div className="absolute top-0 bottom-0 hidden lg:block z-0">
          <StaticImage
            src="../../../assets/images/media-guide-header-desktop.png"
            alt=""
          />
        </div>
        <div className="absolute top-0 bottom-0 lg:hidden z-0">
          <StaticImage
            src="../../../assets/images/media-guide-header-mobile.png"
            alt=""
          />
        </div>
        <div className="max-w-[768px] mx-auto relative py-12 sm:py-14 lg:py-16 px-8 z-10">
          <h1 className="text-white text-3xl lg:text-6xl font-bold leading-[42px] mb-4 lg:mb-6 text-center tracking-wide">
            企業の課題に合わせて
            <br className="sm:hidden" />
            読者体験をデザインする
          </h1>
          <p className="text-white text-xs sm:text-base leading-8 text-center tracking-[0.06em]">
            Webメディア『unprinted®』を通した「顧客企業の課題解決」をサービスとして提供しています。
            タイアップ記事による採用ブランディングや求人広告の掲載など、クライアントの抱える課題に合わせてユーザーの流入元からコンバージョンまで個別に設計します。
          </p>
          <div className="flex gap-6 justify-center mt-4 lg:mt-10">
            <Link
              to="/media-guide/download/"
              className="min-w-[227px] text-center text-sm font-semibold p-4 rounded bg-white block">
              媒体資料を請求する
            </Link>
            <Link
              to="/media-guide/contact/"
              className="min-w-[227px] text-center text-sm font-semibold p-4 rounded bg-white hidden lg:block">
              オンラインで相談する
            </Link>
          </div>
        </div>
      </div>

      <div className="max-w-[768px] mx-auto mb-20 px-5 sm:px-8 lg:px-0">
        <h2 className="text-6xl font-bold mb-8">メディア概要</h2>

        <div className="items-center flex-col-reverse sm:flex-row flex gap-6">
          <div>
            <p className="font-bold mb-4">
              「より良い未来」をつくるデザイナーのためのWork & Lifeマガジン
            </p>
            <p className="leading-8 mb-8">
              「より良い未来をつくる」というテーマでビジネス、哲学、組織論や生活のアイデアなど、デジタルデザイナーたちが日々の業務や生活、自身のキャリアに組み込むことができるような多彩な視点を提供するWebメディアです。
            </p>
          </div>

          <div className="shrink-0">
            <StaticImage
              src="../../../assets/images/logo-vertical.png"
              alt=""
              className=""
              width={188}
              height={150}
            />
          </div>
        </div>

        <div className="flex gap-6">
          <div>
            <span>月間PV：</span>
            <span className="font-bold">180,000</span>
          </div>

          <div>
            <span>月間UU：</span>
            <span className="font-bold">120,000人</span>
          </div>
        </div>
      </div>

      <div className="max-w-[768px] mx-auto mb-14 px-5 sm:px-8 lg:px-0">
        <h2 className="text-6xl font-bold mb-7 leading-[42px]">
          デジタルプロダクトデザイン分野に特化した記事制作
        </h2>
        <p className="leading-8">
          オリジナルの考察記事をはじめ、デザイン学習コンテンツやニュース、イベント情報などデジタルプロダクトに携わるデザイナーの毎日をもっと充実したものにできるようなコンテンツを発信しています。
        </p>
      </div>

      <div className="lg:w-[920px] mx-auto mb-20 px-5 sm:px-8 lg:px-0">
        <div className="flex flex-col sm:flex-row gap-4 mb-4">
          <Link
            to="/articles/future-vision-of-a-digital-agency-product-designer/"
            className="block relative flex-1 aspect-[1/0.708] bg-black">
            <StaticImage
              src="../../../assets/images/future-vision-of-a-digital-agency-product-designer.png"
              alt="元フリーターでバンドマン。異色の経歴をもつデジタル庁プロダクトデザイナーが描く未来"
              width={452}
              className="absolute inset-0"
            />
            <p className="text-white text-sm font-semibold leading-6 p-5 absolute bottom-0">
              元フリーターでバンドマン。異色の経歴をもつデジタル庁プロダクトデザイナーが描く未来
            </p>
          </Link>

          <Link
            to="/articles/is-it-your-responsibility-as-a-designer-to-have-a-monetization-perspective/"
            className="block relative flex-1 aspect-[1/0.708] bg-black">
            <StaticImage
              src="../../../assets/images/is-it-your-responsibility-as-a-designer-to-have-a-monetization-perspective.png"
              alt="収益化の視点を持つことはデザイナーとしての一つの責任？Metaでの経験とT型デザイナーとしてのキャリア"
              width={452}
              className="absolute inset-0"
            />
            <p className="text-white text-sm font-semibold leading-6 p-5 absolute bottom-0">
              収益化の視点を持つことはデザイナーとしての一つの責任？Metaでの経験とT型デザイナーとしてのキャリア
            </p>
          </Link>
        </div>

        <div className="grid sm:grid-cols-2 lg:flex gap-4">
          <Link
            to="/articles/stateless-ui-design/"
            className="block relative flex-1 aspect-[1/0.708] bg-black">
            <StaticImage
              src="../../../assets/images/stateless-ui-design.png"
              alt="スマートなデザインには人間が追いつけない？ステートフルなコンポーネントの問題点"
              width={452}
              className="absolute inset-0"
            />
            <p className="text-white text-sm font-semibold leading-6 p-5 absolute bottom-0">
              スマートなデザインには人間が追いつけない？ステートフルなコンポーネントの問題点
            </p>
          </Link>

          <Link
            to="/articles/figma-config-2024-report-design-networking-event/"
            className="block relative flex-1 aspect-[1/0.708] bg-black">
            <StaticImage
              src="../../../assets/images/figma-config-2024-report-design-networking-event.png"
              alt="Config2024現地レポート速報編！デザインが大好きな人たちの集まる大規模Meetup"
              width={452}
              className="absolute inset-0"
            />
            <p className="text-white text-sm font-semibold leading-6 p-5 absolute bottom-0">
              Config2024現地レポート速報編！デザインが大好きな人たちの集まる大規模Meetup
            </p>
          </Link>

          <Link
            to="/articles/importance-of-self-disclosure-for-smooth-business-communication/"
            className="block relative flex-1 aspect-[1/0.708] bg-black">
            <StaticImage
              src="../../../assets/images/importance-of-self-disclosure-for-smooth-business-communication.png"
              alt="「タイミー」のプロダクトデザイナーが公開した「自分の取扱説明書」。社内における自己開示の大切さとは？"
              width={452}
              className="absolute inset-0"
            />
            <p className="text-white text-sm font-semibold leading-6 p-5  absolute bottom-0">
              「タイミー」のプロダクトデザイナーが公開した「自分の取扱説明書」。社内における自己開示の大切さとは？
            </p>
          </Link>
        </div>
      </div>

      <div className="max-w-[768px] mx-auto mb-20 px-5 sm:px-8 lg:px-0">
        <h2 className="text-6xl font-bold mb-7 leading-[42px]">
          顧客課題解決型タイアップ企画
        </h2>
        <p className="leading-8 mb-8">
          ブランドの認知からユーザーの行動変容まで、デザイナーにリーチするチャネルとして顧客の課題に合わせたユーザー体験を設計します。
        </p>

        <div className="flex flex-col sm:flex-row sm:pr-8">
          <div className="flex gap-8 sm:block flex-1 pt-8 sm:py-8 pl-10 sm:pl-4 bg-[#ECF6F8] z-[4] relative">
            <h3 className="w-20 sm:w-auto text-5 font-bold mb-4">認知</h3>
            <ul className="text-xs leading-6 font-semibold list-disc pl-4">
              <li>サイトトップ掲載</li>
              <li>SNS/メルマガ</li>
              <li>外部広告</li>
            </ul>

            <div className="step-arrow bg-[#ECF6F8]"></div>
          </div>

          <div className="flex gap-8 sm:block flex-1 pt-14 sm:py-8 pl-10 bg-[#DFF0F4] z-[3] relative">
            <h3 className="w-20 sm:w-auto text-5 font-bold mb-4">興味関心</h3>
            <ul className="text-xs leading-6 font-semibold list-disc pl-4">
              <li>タイアップ企画</li>
              <li>インタビュー記事</li>
            </ul>

            <div className="step-arrow bg-[#DFF0F4]"></div>
          </div>

          <div className="flex gap-8 sm:block flex-1 pt-14 sm:py-8 pl-10 bg-[#CBE6ED] z-[2] relative">
            <h3 className="w-20 sm:w-auto text-5 font-bold mb-4">情報収集</h3>
            <ul className="text-xs leading-6 font-semibold list-disc pl-4">
              <li>採用情報の提供</li>
              <li>企業リソースの紹介</li>
            </ul>

            <div className="step-arrow bg-[#CBE6ED]"></div>
          </div>

          <div className="flex gap-8 sm:block flex-1 pt-14 sm:py-8 pl-10 bg-[#B1DAE4] z-[1] relative">
            <h3 className="w-20 sm:w-auto text-5 font-bold mb-4">アクション</h3>
            <ul className="text-xs leading-6 font-semibold list-disc pl-4">
              <li>記事内CTAの最適化</li>
              <li>フォームの埋め込み</li>
            </ul>

            <div className="step-arrow bg-[#B1DAE4]"></div>
          </div>
        </div>

        <h3 className="text-[22px] font-bold border-l-4 border-cyan pl-3 mt-16 mb-8">
          ご相談いただける課題の例
        </h3>
        <ul className="mb-12 sm:mb-16 px-5 lg:px-10 bg-neutral-99 rounded sm:rounded-lg marker:text-cyan list-outside list-disc">
          <li className="richtext-list-item ml-4 py-3 sm:py-4 font-medium text-xs sm:text-sm leading-[1.6] text-neutral-30">
            優秀なシニアデザイナーを採用したい
          </li>
          <li className="richtext-list-item ml-4 py-3 sm:py-4 font-medium text-xs sm:text-sm leading-[1.6] text-neutral-30">
            採用ブランディングのために転職潜在層に向けて社内の取り組みを発信したい
          </li>
          <li className="richtext-list-item ml-4 py-3 sm:py-4 font-medium text-xs sm:text-sm leading-[1.6] text-neutral-30">
            デザイナー向けプロダクトのレビュー記事を拡散したい
          </li>
          <li className="richtext-list-item ml-4 py-3 sm:py-4 font-medium text-xs sm:text-sm leading-[1.6] text-neutral-30">
            デザイナー向けのイベント集客を行いたい
          </li>
        </ul>

        <h3 className="text-[22px] font-bold border-l-4 border-cyan pl-3 mt-16 mb-8">
          課題に合わせた体験設計
        </h3>
        <ul className="mb-12 sm:mb-16 px-5 lg:px-10 bg-neutral-99 rounded sm:rounded-lg marker:text-cyan list-outside list-disc">
          <li className="richtext-list-item ml-4 py-3 sm:py-4 font-medium text-xs sm:text-sm leading-[1.6] text-neutral-30">
            デザイナーならではのトピックを深掘りするインタビュー
          </li>
          <li className="richtext-list-item ml-4 py-3 sm:py-4 font-medium text-xs sm:text-sm leading-[1.6] text-neutral-30">
            自社開発システムによる記事内の体験設計のカスタマイズ
          </li>
          <li className="richtext-list-item ml-4 py-3 sm:py-4 font-medium text-xs sm:text-sm leading-[1.6] text-neutral-30">
            記事トピックに合わせた流入経路の設計
          </li>
          <li className="richtext-list-item ml-4 py-3 sm:py-4 font-medium text-xs sm:text-sm leading-[1.6] text-neutral-30">
            SEO流入を活かしたロングテールでの流入獲得
          </li>
          <li className="richtext-list-item ml-4 py-3 sm:py-4 font-medium text-xs sm:text-sm leading-[1.6] text-neutral-30">
            UXデザインテンプレートやアプリデザインギャラリーといったアセットを活かしたタイアップ企画
          </li>
        </ul>
      </div>

      <div className="max-w-[768px] mx-auto mb-20 px-5 sm:px-8 lg:px-0">
        <h2 className="text-6xl font-bold mb-7 leading-[42px]">お問合せ先</h2>
        <p className="leading-8 mb-16">
          広告出稿・プロモーション・企画のご相談や、媒体を詳しく知りたい場合など、各種ご案内させていただきます。まずはお気軽にご相談ください。
        </p>

        <p className="mb-4">株式会社Artefact「unprinted編集部」</p>
        <p className="font-semibold text-lg">sales@unprinted.design</p>
      </div>

      <NewsletterSubscription type="dark" />
    </Frame>
  );
};

export default MediaGuidePage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const sd = structuredData(page);

  return <SEO page={page} sd={sd} />;
};

export const query = graphql`
  query MediaGuidePage {
    page: contentfulPage(url: { pathname: { eq: "/media-guide/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
  }
`;
