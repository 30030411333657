import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Series from "~/models/series";
import Frame from "~/views/layout/frame";
import SEO from "~/views/components/generic/seo";
import structuredData from "~/helpers/structured-data";
import Header from "~/views/compositions/navigation/header";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Container from "~/views/layout/container";
import ArticleItem from "~/views/components/article/item";
import PopularArticles from "~/views/compositions/article/popular";
import NewsletterSubscription from "~/views/components/newsletter/subscription";

type Props = {
  data: Queries.SeriesPageQuery;
};

const SeriesPage = ({ data }: Props) => {
  const series = new Series(data.series as Queries.ContentfulSeries);
  const { page } = series;

  return (
    <Frame>
      <Header />

      <div className="relative bg-yellow-lighter">
        <Breadcrumb
          breadcrumb={page.breadcrumb}
          className="z-10 relative xl:max-w-[1328px]"
        />

        <Container className="flex gap-10 pb-10 z-10 relative justify-between">
          <div className="pt-6 max-w-[768px]">
            <p className="ml-[2px] mb-3 pl-1 border-l-2 border-black text-black text-2xs font-semibold leading-[1]">
              連載記事
            </p>
            <h1 className="font-extrabold text-6xl sm:text-8xl lg:text-9xl text-black leading-[1.2]">
              {series.title}
            </h1>
            <p className="text-neutral-30 mt-5 md:mt-8 text-xs md:text-base leading-[2em]">
              {page.description}
            </p>
          </div>

          <div className="shrink-0 hidden md:block rounded-2xl isolate overflow-hidden w-[284px]">
            {page.image && (
              <GatsbyImage
                image={getImage(page.image.thumbnail as any)!}
                alt={page.title}
                className=""
                loading="eager"
              />
            )}
          </div>
        </Container>
      </div>
      <Container className="py-container grid grid-cols-12 grid-gap-x ">
        <div className="col-start-1 col-end-13 lg:col-end-10">
          <ul className="grid grid-gap-y">
            {series.items.map((article) => (
              <li key={article.id}>
                <ArticleItem article={article} />
              </li>
            ))}
          </ul>
        </div>

        <div className="hidden lg:block lg:col-start-10 lg:col-end-13">
          <PopularArticles />
        </div>
      </Container>
      <NewsletterSubscription type="dark" />
    </Frame>
  );
};

export default SeriesPage;

export const Head = ({ data }: Props) => {
  const series = new Series(data.series as Queries.ContentfulSeries);
  const { page } = series;
  const sd = structuredData(page);

  return <SEO page={page} sd={sd} />;
};

export const query = graphql`
  query SeriesPage($id: String) {
    series: contentfulSeries(id: { eq: $id }) {
      id
      title
      items {
        ...PageThumbnailFields
      }
      labels {
        ...LabelFields
      }
      page {
        id
        title
        description {
          description
        }
        breadcrumb {
          ...UrlFields
        }
        url {
          ...UrlFields
        }
        image {
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 640, placeholder: NONE, formats: [WEBP])
            }
          }
        }
        type
        releasedAt
        updatedAt
      }
    }
  }
`;
