const crypto = require("crypto");
import React from "react";
import cx from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types";
import {
  renderRichText,
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from "gatsby-source-contentful/rich-text";
import { extractTags } from "~/helpers/extract-tags";
import ExternalLink from "~/views/components/content/external-link";
import InternalLink from "~/views/components/content/internal-link";
import Media from "~/views/components/content/media";
import Tweet from "~/views/components/content/tweet";
import InlineLink from "~/views/components/content/inline-link";
import Podcast from "~/views/components/content/podcast";
import Spotify from "~/views/components/content/spotify";
import Figma from "~/views/components/content/figma";
import useImageModal from "~/helpers/use-image-modal";
import Note from "~/views/components/content/note";
import Summary from "~/views/components/content/summary";

const options = {
  renderMark: {
    [MARKS.BOLD]: (text: string) => <b className="font-bold">{text}</b>,
    [MARKS.UNDERLINE]: (text: string) => (
      <u className="text-underline font-bold">{text}</u>
    ),
  },
  renderNode: {
    [BLOCKS.HEADING_2]: (node: any, children: string) => {
      const value = node.content.reduce((v: string, c: any) => {
        return (v += c.value);
      }, "");

      return (
        <div
          id={crypto.createHash("md5").update(value).digest("hex")}
          className="heading mt-16 sm:mt-20 mb-6 sm:mb-[34px]">
          <h2 className="font-bold tracking-wide text-xl sm:text-5xl mb-2">
            {children}
          </h2>
          <div className="h-[2px] bg-neutral-98 relative">
            <div className="absolute left-0 h-[2px] w-[20%] bg-cyan"></div>
          </div>
        </div>
      );
    },
    [BLOCKS.HEADING_3]: (node: any, children: string) => {
      const value = node.content[0].value;

      return (
        <h3
          id={crypto.createHash("md5").update(value).digest("hex")}
          className="text-xl font-bold leading-[1.6em] mb-6">
          {children}
        </h3>
      );
    },
    [BLOCKS.UL_LIST]: (node: any, children: any) => {
      return (
        <ul className="mb-8 text-neutral-30 leading-8 font-regular list-outside list-disc marker:text-cyan">
          {children}
        </ul>
      );
    },
    [BLOCKS.OL_LIST]: (node: any, children: any) => (
      <ol className="mb-12 sm:mb-16 px-5 lg:px-10 !list-decimal list-outside marker:text-cyan">
        {children}
      </ol>
    ),
    [BLOCKS.LIST_ITEM]: (node: any, children: any) => {
      return (
        <li className="job-post-richtext-list-item ml-4 py-1 leading-[1.6] text-neutral-30">
          {children}
        </li>
      );
    },
    [BLOCKS.TABLE]: (node: any, children: any) => {
      return (
        <div className="[overflow:overlay] mb-12 sm:mb-16">
          <div className="md:table job-post-richtext-table bg-neutral-99 rounded-lg border-spacing-0 w-full border-collapse">
            <div>{children}</div>
          </div>
        </div>
      );
    },
    [BLOCKS.TABLE_ROW]: (node: any, children: any) => (
      <div className="md:table-row border-b border-b-neutral-90 last:border-b-0 border-dashed py-3 md:py-0">
        {children}
      </div>
    ),
    [BLOCKS.TABLE_CELL]: (node: any, children: any) => (
      <div className="first:whitespace-nowrap md:table-cell break-keep py-2 md:py-5 px-4 md:border-r border-dashed border-neutral-90 last:border-r-0 text-xs sm:text-sm">
        {children}
      </div>
    ),
    [INLINES.HYPERLINK]: (node: any, children: string) => (
      <InlineLink to={node.data.uri}>{children}</InlineLink>
    ),
    [BLOCKS.PARAGRAPH]: (node: any, children: string) => (
      <p className="mb-[34px] text-neutral-30 leading-[34px] text-[17px] font-regular">
        {children}
      </p>
    ),
    [BLOCKS.QUOTE]: (node: any, children: any) => {
      return (
        <div className="job-post-richtext-quote flex gap-2 mb-[34px] sm:gap-6 items-start py-4 rounded-lg text-neutral-30 font-bold">
          <img
            src="/images/quote.svg"
            width="24"
            height="24"
            alt="Quote"
            loading="lazy"
          />
          <div>
            <div className="font-medium leading-8 break-all">{children}</div>
          </div>
        </div>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const { handleActivation } = useImageModal();
      const { gatsbyImageData, file, title, description, mimeType } =
        node.data.target;
      const { tags, content } = extractTags(description);

      if (mimeType.indexOf("image") != -1) {
        return (
          <div
            className={cx("my-8 sm:my-16 cursor-zoom-in", {
              "mb-6 sm:mb-[34px]": tags.indexOf("max-height=356") != -1,
            })}
            onClick={() => handleActivation(file.url, tags)}>
            <div
              className={cx({
                "bg-neutral-99 pt-4 sm:pt-6 pb-4 px-4 sm:px-6 rounded sm:rounded-lg":
                  tags.indexOf("background-gray") != -1,
              })}>
              <GatsbyImage
                className={cx(`block rounded-lg isolate bg-neutral-99`, {
                  "border-2 border-cyan shadow-cyan":
                    tags.indexOf("border") != -1,
                  "w-[260px] mx-auto border border-neutral-90-0.6":
                    tags.indexOf("screenshot") != -1 &&
                    tags.indexOf("mobile") != -1,
                  "border border-neutral-90-0.6":
                    tags.indexOf("border-gray") != -1,
                  "!max-h-[356px] !mx-auto":
                    tags.indexOf("max-height=356") != -1,
                  "!max-w-[480px] !mx-auto":
                    tags.indexOf("max-width=480") != -1,
                })}
                image={getImage(node.data.target)!}
                alt={title}
                style={{
                  aspectRatio: `${gatsbyImageData.width}/${gatsbyImageData.height}`,
                }}
              />
            </div>

            {content && (
              <p
                className="mx-auto mt-2 text-xs text-neutral-60 break-all"
                style={{
                  maxWidth:
                    tags.indexOf("max-height=356") != -1 &&
                    tags.indexOf("background-gray") == -1
                      ? `${
                          356 * (gatsbyImageData.width / gatsbyImageData.height)
                        }px`
                      : tags.indexOf("max-width=480") != -1
                      ? "480px"
                      : "auto",
                }}>
                {content}
              </p>
            )}
          </div>
        );
      }

      if (mimeType.indexOf("video") != -1) {
        return (
          <div
            className={cx("my-8 sm:my-16", {
              "mb-6 sm:mb-[34px]": tags.indexOf("max-height=356") != -1,
            })}>
            <video
              autoPlay
              muted
              loop
              playsInline
              className={cx("rounded-lg isolate", {
                "border-2 border-cyan shadow-cyan":
                  tags.indexOf("border") != -1,
                "border border-neutral-90-0.6":
                  tags.indexOf("border-gray") != -1,
                "w-[260px] mx-auto border border-neutral-90-0.6":
                  tags.indexOf("screenshot") != -1 &&
                  tags.indexOf("mobile") != -1,
                "!max-h-[356px] !mx-auto": tags.indexOf("max-height=356") != -1,
              })}>
              <source src={file.url} type={mimeType} />
            </video>
            {content && (
              <p className="mt-2 text-xs text-neutral-60 break-all">
                {content}
              </p>
            )}
          </div>
        );
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      const { type } = node.data.target.internal;

      if (type == "ContentfulComponentLink") {
        if (node.data.target.url.indexOf("www.youtube.com") != -1) {
          return <Media node={node} />;
        }

        return <ExternalLink node={node} />;
      }

      if (type == "ContentfulPage") {
        return <InternalLink node={node} />;
      }

      if (type == "ContentfulComponentNote") {
        return <Note node={node} />;
      }

      if (type == "ContentfulComponentSummary") {
        return <Summary node={node} />;
      }

      if (type == "ContentfulComponentEmbed") {
        if (node.data.target.type == "Tweet") {
          return <Tweet node={node} />;
        }

        if (node.data.target.type == "Podcast") {
          return <Podcast node={node} />;
        }

        if (node.data.target.type == "Spotify") {
          return <Spotify node={node} />;
        }

        if (node.data.target.type == "Figma") {
          return <Figma node={node} />;
        }

        return (
          <div className="my-8 sm:my-16 mx-auto flex flex-row justify-center rounded-lg overflow-hidden">
            <div
              className="overflow-hidden isolate w-full"
              dangerouslySetInnerHTML={{ __html: node.data.target.code.code }}
            />
          </div>
        );
      }
    },
  },
};

type Props = {
  id?: string;
  className?: string;
  children?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
};

const RichText = ({ id, className, children }: Props) => {
  if (!children) return <div></div>;

  return (
    <div
      id={id}
      className={cx("richtext font-feature-normal tracking-[0em]", className)}>
      {renderRichText(children, options as any)}
    </div>
  );
};

export default RichText;
