import React from "react";
import cx from "classnames";

type Props = {
  className?: string;
  height?: number;
  width?: number;
  fill?: string;
};

function Organizer(props: Props) {
  return (
    <svg
      className={cx(props.className)}
      height={props.height || 10}
      width={props.width || 13}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 7.33333C3.1034 7.33333 1.41677 8.26872 0.342975 9.72031C0.111865 10.0327 -0.00369001 10.1889 8.98067e-05 10.4001C0.00301007 10.5632 0.103283 10.7689 0.228926 10.8696C0.391551 11 0.616913 11 1.06764 11H8.93236C9.38309 11 9.60845 11 9.77107 10.8696C9.89672 10.7689 9.99699 10.5632 9.99991 10.4001C10.0037 10.1889 9.88813 10.0327 9.65703 9.72031C8.58323 8.26872 6.8966 7.33333 5 7.33333Z"
        fill="#929095"
      />
      <path
        d="M5 5.5C6.4869 5.5 7.69227 4.26878 7.69227 2.75C7.69227 1.23122 6.4869 0 5 0C3.5131 0 2.30773 1.23122 2.30773 2.75C2.30773 4.26878 3.5131 5.5 5 5.5Z"
        fill="#929095"
      />
    </svg>
  );
}

export default Organizer;
