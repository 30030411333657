import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import cx from "classnames";
import JobPost from "~/models/job-post";
import JobPostCard from "../job/job-post-card";

type Props = {
  className?: string;
};

function SideNavigationJobPost({ className }: Props) {
  const { contentfulJobPosts }: Queries.SideNavigationJobPostQuery =
    useStaticQuery(
      graphql`
        query SideNavigationJobPost {
          contentfulJobPosts: allContentfulJobPost(
            sort: { createdAt: DESC }
            limit: 2
            filter: { closed: { ne: true } }
          ) {
            nodes {
              ...JobPostFields
            }
            pageInfo {
              currentPage
              hasNextPage
              hasPreviousPage
              pageCount
            }
          }
        }
      `
    );

  const jobPosts = contentfulJobPosts.nodes.map(
    (jobPost: any) => new JobPost(jobPost)
  );

  return (
    <div className={cx("mb-16", className)}>
      <p className="font-extrabold text-4xl text-neutral-90 mb-6 leading-[1.2]">
        Jobs
      </p>

      <div>
        {jobPosts.map((post: JobPost) => (
          <JobPostCard
            key={post.id}
            jobPost={post}
            className="mb-8 last:mb-0"
          />
        ))}
      </div>
    </div>
  );
}

export default SideNavigationJobPost;
