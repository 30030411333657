import React from "react";
import cx from "classnames";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ArticleCard from "~/views/components/article/card";
import Collection from "~/models/collection";
import Dotdotdot from "react-dotdotdot";

type Props = {
  className?: string;
};

function Featured({ className }: Props) {
  const { contentfulCollectionPage }: Queries.PopularQuery = useStaticQuery(
    graphql`
      query Featured {
        contentfulCollectionPage(slug: { eq: "featured" }) {
          id
          title
          items {
            id
            internalTitle
            title
            excerpt
            description {
              description
            }
            image {
              thumbnail {
                childImageSharp {
                  gatsbyImageData(
                    width: 1264
                    placeholder: NONE
                    formats: [WEBP]
                  )
                }
                publicURL
              }
            }
            url {
              ...UrlFields
            }
            updatedAt
            labels {
              ...LabelFields
            }
          }
        }
      }
    `
  );

  const featured = new Collection(
    contentfulCollectionPage as Queries.ContentfulCollectionPage
  );

  return (
    <div className={cx(className, "grid grid-cols-12 grid-gap-x items-start")}>
      <div className="hidden md:grid col-start-1 col-end-4">
        <ArticleCard article={featured.items[1]} className="mb-10" />

        <ArticleCard article={featured.items[2]} />
      </div>

      <div className="col-start-1 col-end-13 sm:col-end-9 md:col-start-4 md:col-end-10">
        <Link
          aria-label={featured.items[0].title}
          to={featured.items[0].url.pathname}
          className="bg-neutral-99 block rounded-2xl overflow-hidden isolate">
          <GatsbyImage
            image={getImage(featured.items[0].image?.thumbnail as any)!}
            alt={featured.items[0].title}
            className="transition-all ease-in-out duration-500 hover:opacity-[0.85] hover:scale-[1.005]"
            loading="eager"
          />
        </Link>

        <div className="pt-4 sm:pt-6">
          <Link
            aria-label={featured.items[0].title}
            to={featured.items[0].url.pathname}
            className="block mb-4 font-bold text-4xl sm:text-5xl md:text-5xl w1180:text-7xl leading-[1.4] hover:highlighted-link">
            {featured.items[0].title}
          </Link>

          <Link
            aria-label={featured.items[0].title}
            to={featured.items[0].url.pathname}>
            <Dotdotdot clamp={3} className="text-xs md:text-base leading-7">
              {featured.items[0].excerpt}
            </Dotdotdot>
          </Link>
        </div>
      </div>

      <div className="hidden sm:grid sm:col-start-9 md:col-start-10 col-end-13">
        <ArticleCard article={featured.items[3]} className="mb-4 md:mb-10" />

        <ArticleCard article={featured.items[4]} />
      </div>
    </div>
  );
}

export default Featured;
