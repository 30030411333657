import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Page from "~/models/page";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SEO from "~/views/components/generic/seo";
import structuredData from "~/helpers/structured-data";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Header from "~/views/compositions/navigation/header";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import PageHeader from "~/views/compositions/generic/header";

type Props = {
  data: Queries.ToolListPageQuery;
};

const ToolListPage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const tools = data.tools.nodes.map(
    (tool) => new Page(tool as Queries.ContentfulPage)
  );

  return (
    <Frame>
      <Header />

      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />

      <PageHeader title="デザインツール" description={page.description} />

      <Container className="pb-container grid grid-cols-12">
        <div className="col-span-full">
          <ul className="grid grid-cols-2 md:grid-cols-3 grid-gap-x grid-gap-y">
            {tools.map((tool) => (
              <li key={tool.id}>
                <Link
                  aria-label={tool.title}
                  to={tool.url.pathname}
                  className="block rounded-lg overflow-hidden isolate bg-neutral-99 mb-4">
                  <GatsbyImage
                    image={getImage(tool.image!.cover as any)!}
                    alt={tool.title}
                    loading="eager"
                    className="w-full aspect-[1/0.525] transition-all ease-in-out duration-500 hover:opacity-[0.85] hover:scale-[1.005]"
                  />
                </Link>
                <div className="mb-3">
                  {tool.labels.map((label) => (
                    <span
                      key={label.id}
                      className="text-3xs font-semibold text-cyan-bright border border-cyan-bright rounded-full py-1 px-4">
                      {label.name}
                    </span>
                  ))}
                </div>
                <Link
                  to={tool.url.pathname}
                  className="block font-bold text-lg md:text-2xl mb-3">
                  {tool.title}
                </Link>
                <p className="hidden sm:block text-xs text-neutral-30">
                  {tool.description}
                </p>
              </li>
            ))}
            <li key="coming-soon" className="hidden md:block">
              <StaticImage
                src="../../../assets/images/coming-soon.png"
                alt="UXデザインに役立つ！unprintedオリジナル無料テンプレート集"
              />
            </li>
          </ul>
        </div>
      </Container>

      <NewsletterSubscription type="dark" />
    </Frame>
  );
};

export default ToolListPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const sd = structuredData(page);

  return <SEO page={page} sd={sd} />;
};

export const query = graphql`
  query ToolListPage {
    page: contentfulPage(url: { pathname: { eq: "/tools/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
    tools: allContentfulPage(
      filter: { url: { pathname: { in: ["/resources/", "/explore/"] } } }
      sort: { createdAt: DESC }
    ) {
      nodes {
        id
        internalTitle
        title
        description {
          description
        }
        type
        image {
          cover {
            childImageSharp {
              gatsbyImageData(width: 400, placeholder: NONE, formats: [WEBP])
            }
          }
        }
        url {
          ...UrlFields
        }
        releasedAt
        updatedAt
        labels {
          ...LabelFields
        }
      }
    }
  }
`;
