import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Window from "~/views/identity/window";

type Props = {
  node?: any;
};

function ExternalLink({ node }: Props) {
  const { url, title, image, description } = node.data.target;

  const d = description
    ? description.length > 100
      ? description.substring(0, 100) + "..."
      : description
    : "";

  let domain = new URL(url).hostname;

  return (
    <div className="group my-8 sm:my-16">
      <div className="bg-neutral-99 rounded px-2 py-2 sm:px-5 sm:py-3 flex gap-3 sm:gap-6 items-center">
        <a
          href={url}
          rel="noopener noreferrer"
          target="_blank"
          className="relative block shrink-0 w-[120px] sm:w-[200px] aspect-[1.91/1] overflow-hidden isolate rounded bg-neutral-98">
          {image && (
            <GatsbyImage
              className="aspect-[1.91/1] object-cover transition-all ease-in-out duration-500 hover:opacity-[0.85] group-hover:scale-[1.01]"
              image={getImage(image)!}
              alt={title}
            />
          )}
          <div className="border border-neutral-90-0.6 absolute inset-0 rounded"></div>
        </a>

        <div>
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="block text-xs sm:text-base font-bold text-neutral-30 group-hover:highlighted-link"
            href={url}>
            <span className="align-middle">{title}</span>
            <Window
              color="#2E2E30"
              className="align-middle inline-block ml-[6px] mb-[3px]"
            />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="mb-1 block text-2xs text-neutral-60 break-all"
            href={url}>
            {domain}
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="hidden sm:block text-xs text-neutral-30"
            href={url}>
            {d}
          </a>
        </div>
      </div>
    </div>
  );
}

export default ExternalLink;
