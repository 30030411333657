import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import Page from "~/models/page";
import SEO from "~/views/components/generic/seo";
import structuredData from "~/helpers/structured-data";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Header from "~/views/compositions/navigation/header";
import cx from "classnames";
import Check from "~/views/identity/check";
import ChevronDown from "~/views/identity/chevron-down";
import { navigate } from "@reach/router";

enum State {
  Default,
  Submitting,
  Submitted,
}

type Props = {
  data: Queries.MediaGuideDownloadQuery;
};

const MediaGuideDownload = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);

  const [state, setState] = useState(State.Default);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("自社導入");
  const [issue, setIssue] = useState("デザイナーの採用");
  const [organization, setOrganization] = useState("");
  const [message, setMessage] = useState("");
  const [privacy, setPrivacy] = useState(false);

  const [errors, setErrors] = useState({
    organization: false,
    name: false,
    email: false,
    phoneNumber: false,
    role: false,
    issue: false,
    message: false,
    privacy: false,
  });

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const endpoint = process.env
      .GATSBY_GETFORM_ENDPOINT_MEDIA_GUIDE_DOWNLOAD as string;

    const data = {
      type: "download",
      organization,
      name,
      email,
      phoneNumber,
      role,
      issue,
      message,
      privacy,
    };

    setErrors({
      organization: organization.length == 0,
      name: name.length == 0,
      email: email.length == 0,
      phoneNumber: phoneNumber.length == 0,
      role: role.length == 0,
      issue: issue.length == 0,
      message: message.length == 0,
      privacy: !privacy,
    });

    if (
      organization.length == 0 ||
      name.length == 0 ||
      email.length == 0 ||
      phoneNumber.length == 0 ||
      role.length == 0 ||
      issue.length == 0 ||
      message.length == 0 ||
      !privacy
    ) {
      return;
    }

    try {
      setState(State.Submitting);

      let response = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      if (typeof window !== "undefined") {
        (window as any).dataLayer.push({
          event: "submit",
          type: "download",
        });
      }

      setState(State.Submitted);

      navigate("/media-guide/submitted");
    } catch (error) {
      setState(State.Default);
    }
  }

  return (
    <Frame>
      <Header />

      <Container className="py-container">
        <div className="max-w-[768px] mx-auto">
          <h1 className="mb-8 sm:mb-16 font-bold text-2xl sm:text-6xl text-center">
            媒体資料請求
          </h1>
          <p className="text-neutral-30 leading-[2] mb-8">
            ご入力頂いたメールアドレス宛てに媒体資料を送付させていただきます。
            広告出稿・プロモーション・企画のご相談や、媒体を詳しく知りたい場合など、担当者より各種ご案内させていただきますので、まずはお気軽にご相談ください。
          </p>

          <p className="mb-8 sm:mb-16">
            <span className="text-orange-dark">*</span>
            <span>印は入力必須項目です。</span>
          </p>

          <form onSubmit={(e) => onSubmit(e)}>
            <div className="mb-10">
              <label
                htmlFor="organization"
                className="mb-3 block text-xs text-neutral-30">
                <span>会社名・所属</span>
                <span className="ml-1 text-orange-dark">*</span>
              </label>
              <input
                name="organization"
                type="text"
                className="w-full border border-neutral-60 rounded p-[15px] leading-4 focus-visible:outline-black"
                value={organization}
                onChange={(e) => {
                  setOrganization(e.target.value);
                }}
              />
            </div>

            <div className="mb-10">
              <label
                htmlFor="name"
                className="mb-3 block text-xs text-neutral-30">
                <span>お名前</span>
                <span className="ml-1 text-orange-dark">*</span>
              </label>
              <input
                name="name"
                type="text"
                className="w-full border border-neutral-60 rounded p-[15px] leading-4 focus-visible:outline-black"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>

            <div className="mb-10">
              <label
                htmlFor="email"
                className="mb-3 block text-xs text-neutral-30">
                <span>メールアドレス</span>
                <span className="ml-1 text-orange-dark">*</span>
              </label>
              <input
                name="email"
                type="text"
                className="w-full border border-neutral-60 rounded p-[15px] leading-4 focus-visible:outline-black"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>

            <div className="mb-10">
              <label
                htmlFor="phoneNumber"
                className="mb-3 block text-xs text-neutral-30">
                <span>お電話番号</span>
                <span className="ml-1 text-orange-dark">*</span>
              </label>
              <input
                name="phoneNumber"
                type="text"
                className="w-full border border-neutral-60 rounded p-[15px] leading-4 focus-visible:outline-black"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              />
            </div>

            <div className="mb-10">
              <label
                htmlFor="role"
                className="mb-3 block text-xs text-neutral-30">
                <span>提案の立場</span>
                <span className="ml-1 text-orange-dark">*</span>
              </label>

              <div className="relative">
                <select
                  name="role"
                  id="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  className="w-full border border-neutral-60 rounded p-[15px] leading-4 focus-visible:outline-black appearance-none cursor-pointer">
                  <option value="自社導入">自社導入</option>
                  <option value="代理販売">代理販売</option>
                </select>
                <ChevronDown className="absolute right-3 top-4 pointer-events-none" />
              </div>
            </div>

            <div className="mb-10">
              <label
                htmlFor="role"
                className="mb-3 block text-xs text-neutral-30">
                <span>抱えている課題</span>
                <span className="ml-1 text-orange-dark">*</span>
              </label>

              <div className="relative">
                <select
                  name="issue"
                  id="issue"
                  value={issue}
                  onChange={(e) => setIssue(e.target.value)}
                  className="w-full border border-neutral-60 rounded p-[15px] leading-4 focus-visible:outline-black appearance-none cursor-pointer">
                  <option value="デザイナーの採用">デザイナーの採用</option>
                  <option value="デザイナー向けプロダクト・サービスの販売促進">
                    デザイナー向けプロダクト・サービスの販売促進
                  </option>
                  <option value="イベントの集客">イベントの集客</option>
                  <option value="その他">その他</option>
                </select>
                <ChevronDown className="absolute right-3 top-4 pointer-events-none" />
              </div>
            </div>

            <div className="mb-10">
              <label
                htmlFor="message"
                className="mb-3 block text-xs text-neutral-30">
                <span>お問い合わせ内容</span>
                <span className="ml-1 text-orange-dark">*</span>
              </label>
              <textarea
                name="message"
                className={cx(
                  "w-full h-[144px] border border-neutral-60 rounded p-[15px] leading-4 focus-visible:outline-black",
                  {
                    "border-orange-dark border": errors.message,
                  }
                )}
                value={message}
                onChange={(e) => {
                  setErrors({
                    ...errors,
                    message: false,
                  });
                  setMessage(e.target.value);
                }}
              />
            </div>

            <div className="flex flex-col sm:flex-row gap-6 mb-10">
              <div className="flex-1">
                <div className="mb-4 flex items-center">
                  <input
                    id="privacy"
                    name="privacy"
                    type="checkbox"
                    className="mr-2 cursor-pointer border border-neutral-60 accent-black w-4 h-4"
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        privacy: false,
                      });
                      setPrivacy(e.target.checked);
                    }}
                  />
                  <label
                    htmlFor="privacy"
                    className="text-xs text-neutral-30 cursor-pointer">
                    <span>個人情報の取り扱いについて同意します</span>
                    <span className="ml-1 text-orange-dark">*</span>
                  </label>
                </div>
                <p className="pl-6 text-3xs text-neutral-30 leading-[1.5]">
                  お客様の情報は大切に保管し、unprinted及び関連会社以外に提供することはありません。個人情報の取り扱いについての詳細は
                  <Link
                    aria-label="プライバシーポリシー"
                    to="/privacy-policy"
                    className="underline hover:inline-link">
                    プライバシーポリシー
                  </Link>
                  をご覧ください。
                </p>
              </div>
            </div>

            {Object.values(errors).indexOf(true) != -1 && (
              <p className="max-w-screen-sm mx-auto mb-6 text-3xs text-orange-dark text-center">
                <span>※</span>
                {errors.organization && (
                  <span className="mr-1">「会社名・所属」</span>
                )}
                {errors.name && <span className="mr-1">「お名前」</span>}
                {errors.email && (
                  <span className="mr-1">「メールアドレス」</span>
                )}
                {errors.phoneNumber && (
                  <span className="mr-1">「お電話番号」</span>
                )}
                {errors.role && <span className="mr-1">「提案の立場」</span>}
                {errors.issue && (
                  <span className="mr-1">「抱えている課題」</span>
                )}

                {errors.message && (
                  <span className="mr-1">「お問い合わせ内容」</span>
                )}
                {errors.privacy && (
                  <span className="mr-1">
                    「個人情報の取り扱いについて同意」
                  </span>
                )}
                <span>
                  は必須項目です。
                  <br />
                  ご入力の上、送信ボタンを押してください。
                </span>
              </p>
            )}

            <div className="flex justify-center">
              <button
                aria-label="送信"
                type="submit"
                className={cx("cta flex gap-3 items-center ", {
                  "pointer-events-none": state != State.Default,
                })}>
                {state == State.Submitting && <div className="loader"></div>}
                {state == State.Submitted && <Check width={20} />}
                <span>送信</span>
              </button>
            </div>
          </form>
        </div>
      </Container>
    </Frame>
  );
};

export default MediaGuideDownload;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const sd = structuredData(page);

  return <SEO page={page} sd={sd} />;
};

export const query = graphql`
  query MediaGuideDownload {
    page: contentfulPage(url: { pathname: { eq: "/media-guide/download/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
  }
`;
