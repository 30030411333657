import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Dotdotdot from "react-dotdotdot";
import cx from "classnames";

type Props = {
  node?: any;
};

const labels: any = {
  series: "この記事の関連シリーズ",
  "ux-design": "UXデザインに関する記事をもっと読む",
  "ui-design": "UIデザインに関する記事をもっと読む",
  "web-design": "Webデザインに関する記事をもっと読む",
  branding: "ブランディングに関する記事をもっと読む",
  "graphic-design": "グラフフィックデザインに関する記事をもっと読む",
  business: "ビジネスに関する記事をもっと読む",
};

const label = (pathname: string) => {
  const first = pathname.split("/")[1];

  if (Object.keys(labels).indexOf(first) == -1) return "関連記事";

  return labels[first];
};

function InternalLink({ node }: Props) {
  const { title, internalTitle, pageURL, image, excerpt, desc, type } =
    node.data.target;

  return (
    <div className="group my-8 sm:my-16">
      <div className="flex gap-1 items-center mb-1">
        <svg
          width="14"
          height="11"
          viewBox="0 0 14 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.83467 9.73501C7.43943 10.334 6.56057 10.334 6.16533 9.73501L1.09502 2.05074C0.656331 1.38588 1.13315 0.499999 1.9297 0.499999L12.0703 0.5C12.8669 0.5 13.3437 1.38589 12.905 2.05074L7.83467 9.73501Z"
            fill="#7DC1D2"
          />
        </svg>

        <span className="text-neutral-60 text-xs font-bold">
          {label(pageURL.pathname)}
        </span>
      </div>

      <div className="bg-neutral-99 rounded px-2 py-2 sm:px-5 sm:py-3 flex gap-3 sm:gap-6 items-center">
        <Link
          aria-label={internalTitle || title}
          to={pageURL.pathname}
          className={cx(
            "relative block shrink-0 overflow-hidden isolate rounded",
            {
              "w-[90px] sm:w-[120px] aspect-[1/0.88]": type == "Article",
              "w-[90px] sm:w-[160px] aspect-[1/0.525]": type == "News",
            }
          )}>
          <GatsbyImage
            image={getImage(image?.thumbnail as any)!}
            alt={internalTitle || title}
            loading="eager"
            className="h-full w-full transition-all ease-in-out duration-500 hover:opacity-[0.85] group-hover:scale-[1.01]"
          />
          <div className="border border-neutral-90-0.6 absolute inset-0 rounded"></div>
        </Link>

        <div>
          <Link
            aria-label={internalTitle || title}
            to={pageURL.pathname}
            className="block text-xs sm:text-base font-bold text-neutral-30 group-hover:highlighted-link">
            {internalTitle || title}
          </Link>

          <Link
            aria-label={internalTitle || title}
            to={pageURL.pathname}
            className="hidden sm:block text-xs text-neutral-30 mt-2">
            <Dotdotdot clamp={3}>{excerpt || desc?.desc}</Dotdotdot>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default InternalLink;
