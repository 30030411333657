import React from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { renderToString } from "react-dom/server";
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from "gatsby-source-contentful/rich-text";

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const { publicUrl, title, mimeType } = node.data.target;

      if (mimeType.indexOf("image") != -1) {
        return (
          <img src={`${process.env.GATSBY_SITE_URL}${publicUrl}`} alt={title} />
        );
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      if (!node.data.target) return;

      const { type } = node.data.target.internal;

      if (type == "ContentfulPage") {
        const { title, pageURL } = node.data.target;

        return (
          <div>
            <span>関連記事: </span>
            <a href={`${process.env.GATSBY_SITE_URL}${pageURL.pathname}`}>
              {title}
            </a>
          </div>
        );
      }
    },
  },
};

const feedContent = (
  content: RenderRichTextData<ContentfulRichTextGatsbyReference>
) => {
  return renderToString(renderRichText(content, options) as any);
};

export default feedContent;
