import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import cx from "classnames";
import JobPost from "~/models/job-post";
import Location from "~/views/identity/location";
import Organizer from "~/views/identity/organizer";
import JPY from "~/views/identity/jpy";

type Props = {
  jobPost: JobPost;
  className?: string;
};

function JobPostCard({ jobPost, className }: Props) {
  return (
    <Link
      to={`/jobs/${jobPost.id}/`}
      className={cx(
        className,
        "bg-neutral-99 rounded-lg p-2 grid grid-rows-subgrid row-span-3 gap-3"
      )}>
      <div className="relative bg-white rounded flex justify-center">
        <GatsbyImage
          image={getImage(jobPost.company.logo as any)!}
          alt={jobPost.title}
          loading="eager"
          objectFit="contain"
          className="max-h-[160px] rounded-lg overflow-hidden isolate transition-all ease-in-out duration-500 hover:opacity-[0.85] hover:scale-[1.005]"
        />

        <div className="absolute left-2 -bottom-3 bg-white text-4xs font-semibold text-cyan-strong leading-[16px] py-1 px-2 rounded-full border border-neutral-90">
          {jobPost.labels[0].name}
        </div>
      </div>

      <div>
        <div className="hover:highlighted-link px-2 mt-3 block font-bold text-lg sm:text-xl md:text-2xl leading-[1.4em] mb-3">
          {jobPost.title}
        </div>

        <div className="px-2 block text-2xs sm:text-xs leading-[1.4em]">
          {jobPost.company.name}
        </div>
      </div>

      <div className="px-2 flex flex-col gap-2 mb-3 mt-3">
        <div className="flex gap-1 items-center">
          <Location />
          <span className="text-3xs text-neutral-60 font-medium">
            {jobPost.locations.map((location) => location.name).join("/")}
          </span>
        </div>

        <div className="flex gap-1 items-center">
          <Organizer />
          <span className="text-3xs text-neutral-60 font-medium">
            {jobPost.employmentTypes.map((type) => type.name).join("/")}
          </span>
        </div>

        <div className="flex gap-1 items-center">
          <JPY />
          <span className="text-3xs text-neutral-60 font-medium">
            {!!jobPost.minimumSalary && (
              <span>{`${jobPost.minimumSalary}万円`}</span>
            )}

            {(!!jobPost.minimumSalary || !!jobPost.maximumSalary) && (
              <span>〜</span>
            )}

            {!!jobPost.maximumSalary && (
              <span>{`${jobPost.maximumSalary}万円`}</span>
            )}

            {!jobPost.minimumSalary && !jobPost.maximumSalary && <span>-</span>}
          </span>
        </div>
      </div>
    </Link>
  );
}

export default JobPostCard;
