import React from "react";
import cx from "classnames";

type Props = {
  className?: string;
  height?: number;
  width?: number;
  fill?: string;
};

function JPY(props: Props) {
  return (
    <svg
      className={cx(props.className)}
      height={props.height || 16}
      width={props.width || 12}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.29904 2.56707C3.53819 2.429 3.84398 2.51094 3.98206 2.75008L6 6.24527L8.01794 2.75008C8.15602 2.51094 8.46181 2.429 8.70096 2.56707L9.56698 3.06707C9.80613 3.20514 9.88807 3.51094 9.75 3.75008L8.10814 6.59387H9.5C9.77614 6.59387 10 6.81772 10 7.09387V8.09387C10 8.37001 9.77614 8.59387 9.5 8.59387H7V9.59387H9.5C9.77614 9.59387 10 9.81772 10 10.0939V11.0939C10 11.37 9.77614 11.5939 9.5 11.5939H7V13.2939C7 13.57 6.77614 13.7939 6.5 13.7939H5.5C5.22386 13.7939 5 13.57 5 13.2939V11.5939H2.5C2.22386 11.5939 2 11.37 2 11.0939V10.0939C2 9.81772 2.22386 9.59387 2.5 9.59387H5V8.59387H2.5C2.22386 8.59387 2 8.37001 2 8.09387V7.09387C2 6.81772 2.22386 6.59387 2.5 6.59387H3.89186L2.25 3.75008C2.11193 3.51094 2.19387 3.20514 2.43302 3.06707L3.29904 2.56707Z"
        fill="#929095"
      />
    </svg>
  );
}

export default JPY;
