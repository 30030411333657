export default class JobPostSearchConditions {
  categories: string[] = [];
  types: string[] = [];
  labels: string[] = [];
  locations: string[] = [];

  constructor(conditions?: any) {
    if (conditions.categories) {
      this.categories = [...new Set(conditions.categories.split(","))] as any;
    }

    if (conditions.types) {
      this.types = [...new Set(conditions.types.split(","))] as any;
    }

    if (conditions.labels) {
      this.labels = [...new Set(conditions.labels.split(","))] as any;
    }

    if (conditions.locations) {
      this.locations = [...new Set(conditions.locations.split(","))] as any;
    }
  }

  get isEmpty() {
    return (
      this.categories.length == 0 &&
      this.types.length == 0 &&
      this.labels.length == 0 &&
      this.locations.length == 0
    );
  }

  add = (field: string, param: string) => {
    switch (field) {
      case "categories":
        this.categories = [...new Set(this.categories.concat(param))] as any;
        break;
      case "types":
        this.types = [...new Set(this.types.concat(param))] as any;
        break;
      case "labels":
        this.labels = [...new Set(this.labels.concat(param))] as any;
        break;
      case "locations":
        this.locations = [...new Set(this.locations.concat(param))] as any;
        break;
      default:
        break;
    }
  };

  remove = (field: string, param: string) => {
    switch (field) {
      case "categories":
        this.categories = this.categories.filter(
          (category) => category != param
        );
        break;
      case "types":
        this.types = this.types.filter((type) => type != param);
        break;
      case "labels":
        this.labels = this.labels.filter((label) => label != param);
        break;
      case "locations":
        this.locations = this.locations.filter((location) => location != param);
        break;
      default:
        break;
    }
  };

  toggle = (field: string, param: string) => {
    if (this.has(field, param)) {
      this.remove(field, param);
    } else {
      this.add(field, param);
    }
  };

  has = (field: string, param: string) => {
    switch (field) {
      case "categories":
        return this.categories.indexOf(param) != -1;
      case "types":
        return this.types.indexOf(param) != -1;
      case "labels":
        return this.labels.indexOf(param) != -1;
      case "locations":
        return this.locations.indexOf(param) != -1;
      default:
        break;
    }
  };

  urlParams = () => {
    let params = [];

    if (this.categories.length > 0) {
      params.push(`categories=${this.categories.join(",")}`);
    }

    if (this.types.length > 0) {
      params.push(`types=${this.types.join(",")}`);
    }

    if (this.labels.length > 0) {
      params.push(`labels=${this.labels.join(",")}`);
    }

    if (this.locations.length > 0) {
      params.push(`locations=${this.locations.join(",")}`);
    }

    return params.join("&");
  };

  algoliaFacetFilters = () => {
    const facets = [];

    if (this.categories.length > 0) {
      facets.push(this.categories.map((v: any) => `categories.slug:${v}`));
    }

    if (this.types.length > 0) {
      facets.push(this.types.map((v: any) => `employmentTypes.slug:${v}`));
    }

    if (this.labels.length > 0) {
      facets.push(this.labels.map((v: any) => `labels.slug:${v}`));
    }

    if (this.locations.length > 0) {
      facets.push(this.locations.map((v: any) => `locations.slug:${v}`));
    }

    return facets;
  };
}
