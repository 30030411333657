import React, { useState } from "react";
import { graphql } from "gatsby";
import Page from "~/models/page";
import Resource from "~/models/resource";
import SEO from "~/views/components/generic/seo";
import structuredData from "~/helpers/structured-data";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Header from "~/views/compositions/navigation/header";
import ResourceCard from "~/views/components/resource/card";
import PageHeader from "~/views/compositions/generic/header";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import Tagline from "~/views/components/generic/tagline";

type Props = {
  data: Queries.ResourceListQuery;
};

const ResourceList = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);

  const resources = data.resources.nodes.map(
    (resource) => new Resource(resource as Queries.ContentfulPage)
  );

  return (
    <Frame>
      <Header />
      <Tagline />
      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />

      <PageHeader
        title="UXデザインに役立つテンプレート集"
        description={page.description}
      />

      <Container className="pb-container">
        <ul className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 grid-gap-x grid-gap-y">
          {resources.map((resource) => (
            <li key={resource.id}>
              <ResourceCard resource={resource} />
            </li>
          ))}
        </ul>
      </Container>

      <NewsletterSubscription type="dark" />
    </Frame>
  );
};

export default ResourceList;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const sd = structuredData(page);

  return <SEO page={page} sd={sd} />;
};

export const query = graphql`
  query ResourceList {
    page: contentfulPage(url: { pathname: { eq: "/resources/" } }) {
      id
      title
      image {
        cover {
          publicURL
        }
      }
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
    resources: allContentfulPage(
      filter: { type: { eq: "Resource" } }
      sort: { releasedAt: DESC }
    ) {
      nodes {
        ...PageThumbnailFields
      }
    }
  }
`;
