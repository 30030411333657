import React from "react";
import { graphql, Link } from "gatsby";
import Collection from "~/models/collection";
import Page from "~//models/page";
import SEO from "~/views/components/generic/seo";
import structuredData from "~/helpers/structured-data";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Header from "~/views/compositions/navigation/header";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import formatdate from "~/helpers/formatdate";
import Tagline from "~/views/components/generic/tagline";
import Paragraph from "~/views/components/content/paragraph";

type Props = {
  data: Queries.AboutUsPageQuery;
};

const AboutUsPage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);

  const social = new Collection(
    data.social as Queries.ContentfulCollectionPage
  );
  const lifestyle = new Collection(
    data.lifestyle as Queries.ContentfulCollectionPage
  );
  const workstyle = new Collection(
    data.workstyle as Queries.ContentfulCollectionPage
  );
  const design = new Collection(
    data.design as Queries.ContentfulCollectionPage
  );

  return (
    <Frame>
      <Header />
      <Tagline />
      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />

      <div className="sm:px-8 pt-9 sm:pb-4">
        <div className="max-w-[680px] mx-auto">
          <div className="hidden sm:block">
            <StaticImage
              src="../../../assets/images/brand-header-desktop.png"
              alt="unprinted | より良い未来」をつくるデザイナーのためのWork & Lifeマガジン"
              className="rounded-xl"
            />
          </div>
          <div className="sm:hidden">
            <StaticImage
              src="../../../assets/images/brand-header-mobile.png"
              alt="unprinted | より良い未来」をつくるデザイナーのためのWork & Lifeマガジン"
            />
          </div>
        </div>
      </div>

      <Container className="py-14 sm:py-16">
        <div className="mb-16 sm:mb-28 max-w-[680px] mx-auto">
          <h2 className="mb-16 font-bold text-3xl sm:text-6xl flex gap-4">
            <img
              src="/images/quote.svg"
              width="24"
              height="24"
              alt="Quote"
              loading="lazy"
            />
            <span>デザイナーってなんだろう？</span>
          </h2>
          <Paragraph>
            多くのデジタルデザイナーが一度は抱えたことがあるであろう「デザイナーってなんだろう」という疑問。
          </Paragraph>
          <Paragraph>
            数年前まではモバイルアプリのUI設計を行なっていたのに、気づいたらユーザーリサーチを担当していたり、キャリアが進むにつれて組織管理に取り組むデザインマネージャーになっていたり。取り組む課題が形を変えていっても、どういうわけか私たちはデザイナーというアイデンティティを手放すことはなく、多くの領域を”デザイン”に取り込みながら成長してきたのだと思います。
          </Paragraph>
        </div>

        <div className="mb-16 sm:mb-28 max-w-[680px] mx-auto">
          <h2 className="mb-16 font-bold text-3xl sm:text-6xl ">
            私たちが1pxの変化を生むたびに、誰かの未来が少しずつ良くなっていく。
          </h2>
          <Paragraph>
            取り組む課題は様々だけど、手元で生み出した価値がデジタルテクノロジーを通して多くの人々に届くという環境でデザインに取り組む私たちは「1pxの変化を生むたびに、誰かの未来が少しずつ良くなっていく」というやりがいを共有しているのではないでしょうか？
          </Paragraph>
          <Paragraph>
            デジタルデザイナーが取り組む課題は多岐にわたるものの、私たちに共通するのはこのようにデザインを通して「より良い未来」をつくっているということだと思います。小さな変化が大きなインパクトにつながるという可能性を持ったデジタルデザインの世界で活躍するデザイナーたちと一緒に、「より良い未来」をつくっていきたいという思いで『unprinted』はコンテンツを発信します。
          </Paragraph>
        </div>

        <div className="mb-16 sm:mb-28 max-w-[680px] mx-auto">
          <h2 className="mb-16 font-bold text-3xl sm:text-6xl ">
            「より良い未来」を大きなテーマとした編集方針
          </h2>
          <Paragraph>
            オリジナル記事をはじめ、日々のニュースからイベント、企業紹介まで「より良い未来をつくる」というテーマに沿ったトピックやキーワードを取り上げて、ビジネス、哲学、組織論や生活のアイデアなど、デザイナーたちが日々の業務や生活、自身のキャリアに組み込むことができるような多彩な視点をコンテンツに織り込みます。
          </Paragraph>
          <Paragraph>
            「Work &
            Lifeマガジン」という位置付けにもとづき、『unprinted』のそれぞれの記事はゆるやかに以下の４トピックに属し、それぞれ「より良い未来」
            と「社会・文化生活・仕事・デザイン」を掛け合わせたものとなります。
          </Paragraph>
        </div>

        <div className="mb-16 max-w-[680px] mx-auto">
          <h3 className="mb-2 text-3xl sm:text-5xl">
            <span className="align-middle text-[34px] text-neutral-90-0.6 font-extrabold">
              #
            </span>
            <span className="align-middle ml-2 font-bold">
              より良い未来の探求
            </span>
          </h3>

          <p className="font-bold text-sm hidden md:block">
            社会的インパクトを持ったデジタルサービスや他の領域での事業、海外の取り組みの紹介、デザインに関する考察。
          </p>

          <ul className="mt-6 sm:mt-14">
            {social.items.map((article) => (
              <li key={article.id} className="mb-8 sm:mb-10">
                <ArticleCard article={article} />
              </li>
            ))}
          </ul>
        </div>

        <div className="mb-16 max-w-[680px] mx-auto">
          <h3 className="mb-2 text-3xl sm:text-5xl">
            <span className="align-middle text-[34px] text-neutral-90-0.6 font-extrabold">
              #
            </span>
            <span className="align-middle ml-2 font-bold">文化と生活</span>
          </h3>

          <p className="font-bold text-sm hidden md:block">
            デザイナーのライフスタイルに焦点を当てて『unprinted』的な視点で暮らしのアイデアを紹介。
          </p>

          <ul className="mt-6 sm:mt-14">
            {lifestyle.items.map((article) => (
              <li key={article.id} className="mb-8 sm:mb-10">
                <ArticleCard article={article} />
              </li>
            ))}
          </ul>
        </div>

        <div className="mb-16 max-w-[680px] mx-auto">
          <h3 className="mb-2 text-3xl sm:text-5xl">
            <span className="align-middle text-[34px] text-neutral-90-0.6 font-extrabold">
              #
            </span>
            <span className="align-middle ml-2 font-bold">働くを楽しむ</span>
          </h3>

          <p className="font-bold text-sm hidden md:block">
            「より良い未来」をつくるデザイナーの仕事をもっと楽しめるようなアイデアを紹介。
          </p>

          <ul className="mt-6 sm:mt-14">
            {workstyle.items.map((article) => (
              <li key={article.id} className="mb-8 sm:mb-10">
                <ArticleCard article={article} />
              </li>
            ))}
          </ul>
        </div>

        <div className="mb-16 sm:mb-28 max-w-[680px] mx-auto">
          <h3 className="mb-2 text-3xl sm:text-5xl">
            <span className="align-middle text-[34px] text-neutral-90-0.6 font-extrabold">
              #
            </span>
            <span className="align-middle ml-2 font-bold">未来をつくる</span>
          </h3>

          <p className="font-bold text-sm hidden md:block">
            近い未来である「明日」に目を向けて、日々の業務に役立つTipsやHow-to、便利なデザインリソースを紹介。
          </p>

          <ul className="mt-6 sm:mt-14">
            {design.items.map((article) => (
              <li key={article.id} className="mb-8 sm:mb-10">
                <ArticleCard article={article} />
              </li>
            ))}
          </ul>
        </div>

        <div className="mb-16 sm:mb-28 max-w-[680px] mx-auto">
          <h2 className="mb-16 font-bold text-3xl sm:text-6xl ">
            記事を書いてみませんか？
          </h2>
          <Paragraph>
            『unprinted』では、多様な視点を取り入れて進化していくためにも多くのデザイナーが関わりコンテンツを発信しています。何かしらunprintedのコンテンツ制作に関わってみたいという方は、ぜひ一度お話ししましょう。
            <Link
              aria-label="お問い合わせ"
              to="/contact/"
              className="underline hover:inline-link underline-offset-2">
              お問い合わせ
            </Link>
            より、ご連絡お待ちしております。
          </Paragraph>
        </div>

        <div className="mb-16 max-w-[680px] mx-auto">
          <h2 className="mb-16 font-bold text-3xl sm:text-6xl ">
            運営企業情報
          </h2>
          <div className="text-neutral-30">
            <dl className="flex flex-col sm:flex-row gap-0 sm:gap-6 mb-4">
              <dt className="font-bold min-w-[188px]">会社名</dt>
              <dd>株式会社Artefact</dd>
            </dl>
            <dl className="flex flex-col sm:flex-row gap-0 sm:gap-6 mb-4">
              <dt className="font-bold min-w-[188px]">所在地</dt>
              <dd>東京都目黒区駒場4-6-2 Y-5 501</dd>
            </dl>
            <dl className="flex flex-col sm:flex-row gap-0 sm:gap-6 mb-4">
              <dt className="font-bold min-w-[188px]">設立</dt>
              <dd>2015年</dd>
            </dl>
            <dl className="flex flex-col sm:flex-row gap-0 sm:gap-6">
              <dt className="font-bold min-w-[188px]">URL</dt>
              <dd>
                <a href="https://artefact.co.jp" target="_blank">
                  https://artefact.co.jp
                </a>
              </dd>
            </dl>
          </div>
        </div>
      </Container>

      <NewsletterSubscription type="dark" />
    </Frame>
  );
};

export default AboutUsPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const sd = structuredData(page);

  return <SEO page={page} sd={sd} />;
};

function ArticleCard({ article }: any) {
  return (
    <div>
      <div className="flex items-start flex-row gap-4 ">
        <Link
          aria-label={article.title}
          to={article.url.pathname}
          className="block aspect-[1/0.88] shrink-0 w-[110px] sm:w-[160px] overflow-hidden isolate bg-neutral-99">
          {article.image && (
            <GatsbyImage
              image={getImage(article.image.thumbnail as any)!}
              alt={article.title}
              loading="eager"
              className="rounded-lg overflow-hidden isolate transition-all ease-in-out duration-500 hover:opacity-[0.85] hover:scale-[1.005]"
            />
          )}
        </Link>

        <div>
          <div className="mb-1 flex items-center font-medium">
            <Link
              to={`/tags/${article.category?.slug}/`}
              className="text-cyan-strong mr-1 text-4xs hover:underline underline-offset-2">
              {article.category?.name}
            </Link>
            <span className="text-neutral-60 mr-1 text-4xs">・</span>
            <span className="text-neutral-60 text-3xs">
              {formatdate(article.releasedAt)}
            </span>
          </div>

          <Link
            aria-label={article.title}
            to={article.url.pathname}
            className="block font-bold leading-[1.4] hover:underlined-link ">
            {article.title}
          </Link>
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  query AboutUsPage {
    page: contentfulPage(url: { pathname: { eq: "/about-us/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
    social: contentfulCollectionPage(
      slug: { eq: "exploring-a-better-future" }
    ) {
      id
      slug
      title
      subtitle
      items {
        ...PageThumbnailFields
      }
    }
    lifestyle: contentfulCollectionPage(slug: { eq: "culture-and-lifestyle" }) {
      id
      slug
      title
      subtitle
      items {
        ...PageThumbnailFields
      }
    }
    workstyle: contentfulCollectionPage(slug: { eq: "workstyle" }) {
      id
      slug
      title
      subtitle
      items {
        ...PageThumbnailFields
      }
    }
    design: contentfulCollectionPage(slug: { eq: "shaping-a-better-future" }) {
      id
      slug
      title
      subtitle
      items {
        ...PageThumbnailFields
      }
    }
  }
`;
