import React from "react";
import { graphql, navigate } from "gatsby";
import Page from "~/models/page";
import SEO from "~/views/components/generic/seo";
import structuredData from "~/helpers/structured-data";
import Frame from "~/views/layout/frame";
import Header from "~/views/compositions/navigation/header";
import Hero from "~/views/compositions/explore/hero";
import FlowCard from "~/views/components/flow/card";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Palette from "~/views/layout/palette";
import FlowType from "~/models/flow-type";
import ExploreFooter from "~/views/compositions/explore/footer";
import FlowSearch from "~/views/components/flow/search";

type Props = {
  data: Queries.ExplorePageQuery;
};

const ExplorePage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const flowTypes = data.types.nodes.map(
    (type) => new FlowType(type as Queries.ContentfulFlowType)
  );

  const onSearch = (query: string) => {
    if (query.trim() == "") return;
    (document.activeElement as any).blur();

    navigate(`/explore/search/?q=${query}`);
  };

  return (
    <Frame>
      <Header />

      <div className="bg-[#13303F] relative z-20">
        <div className="absolute inset-0 overflow-hidden">
          <img
            src="/images/explore-hero-01.png"
            height="455"
            width="856"
            alt=""
            loading="lazy"
            className="absolute -top-[189px] -left-[320px] z-0 hidden lg:block"
          />
          <img
            src="/images/explore-hero-02.png"
            height="654"
            width="946"
            alt=""
            loading="lazy"
            className="absolute -top-[168px] -right-[260px] z-0 hidden lg:block"
          />
        </div>

        <Breadcrumb
          breadcrumb={page.breadcrumb}
          background="dark"
          className="z-10 relative !px-5 md:!px-14"
        />

        <Hero
          title="Explore user flows"
          description={page.description}
          className="z-10 relative">
          <FlowSearch
            onSearch={(query: string) => onSearch(query)}
            defaultSearchQuery=""
          />
        </Hero>
      </div>

      <div className="bg-neutral-99 py-8 md:py-12">
        {flowTypes.map((type: FlowType) => (
          <Palette
            key={type.id}
            title={type.name}
            label={type.label}
            slug={type.slug}>
            <ul
              onMouseEnter={() => {
                document
                  .getElementsByTagName("body")[0]
                  .classList.add("preventXScroll");
              }}
              onMouseLeave={() => {
                document
                  .getElementsByTagName("body")[0]
                  .classList.remove("preventXScroll");
              }}
              className="flex gap-2 sm:gap-6 mx-auto overflow-x-auto px-5 md:px-14 scroll-px-5 md:scroll-px-14 hide-scrollbar snap-x snap-proximity">
              {type.flows
                .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
                .map((flow) => (
                  <li
                    key={flow.id}
                    className="shrink-0 w-[180px] sm:min-w-[300px] snap-start">
                    <FlowCard flow={flow} />
                  </li>
                ))}
            </ul>
          </Palette>
        ))}
      </div>

      <ExploreFooter />
    </Frame>
  );
};

export default ExplorePage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const sd = structuredData(page);

  return <SEO page={page} sd={sd} />;
};

export const query = graphql`
  query ExplorePage {
    page: contentfulPage(url: { pathname: { eq: "/explore/" } }) {
      ...PageFields
    }
    types: allContentfulFlowType(
      sort: { createdAt: ASC }
      filter: {
        slug: { nin: ["others", "adding-friends", "referral", "plans"] }
      }
    ) {
      nodes {
        id
        slug
        name
        flow {
          id
          slug
          name
          createdAt
          type {
            id
            slug
            name
          }
          posters {
            gatsbyImageData(width: 320, placeholder: NONE, formats: [WEBP])
          }
          service {
            id
            slug
            name
            url
            category {
              id
              name
              slug
            }
            logo {
              gatsbyImageData(width: 120, placeholder: NONE, formats: [WEBP])
            }
          }
        }
      }
    }
  }
`;
