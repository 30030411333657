import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Frame from "~/views/layout/frame";
import SEO from "~/views/components/generic/seo";
import structuredData from "~/helpers/structured-data";
import Header from "~/views/compositions/navigation/header";
import JobPost from "~/models/job-post";
import Page from "~/models/page";
import Location from "~/views/identity/location";
import Organizer from "~/views/identity/organizer";
import JPY from "~/views/identity/jpy";
import Window from "~/views/identity/window";
import RichText from "~/views/components/content/job-post-richtext";
import JobPostLink from "~/views/components/job/job-post-link";
import ArticleItem from "~/views/components/article/item";
import TableOfContents from "~/views/components/job/table-of-contents";
import ArticleCard from "~/views/components/article/card";

type Props = {
  data: Queries.JobPostPageQuery;
};

const JobPostPage = ({ data }: Props) => {
  const jobPost = new JobPost(data.jobPost as Queries.ContentfulJobPost);

  function onApplication(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (typeof window !== "undefined") {
      (window as any).dataLayer.push({
        event: "apply",
        type: "jobpost",
        jobPostId: jobPost.id,
        company: jobPost.company.name,
        title: jobPost.title,
        link_target: jobPost.applicationUrl,
      });
    }
  }

  return (
    <Frame className="pb-[100px]">
      <Header />

      <div className="px-5 sm:px-8 md:px-[60px] w1180:px-0 lg:max-w-[1112px] lg:mx-auto mt-8">
        <ul className="">
          <li key="/" className="inline-block mr-2 font-medium text-4xs">
            <Link
              to="/"
              className="mr-2 inline-block align-middle hover:underline text-neutral-60 hover:underline-offset-2">
              ホーム
            </Link>
            <span className="inline-block align-middle text-neutral-90">/</span>
          </li>
          <li key="/jobs/" className="inline-block mr-2 font-medium text-4xs">
            <Link
              to="/jobs/"
              className="mr-2 inline-block align-middle hover:underline text-neutral-60 hover:underline-offset-2">
              求人
            </Link>
            <span className="inline-block align-middle text-neutral-90">/</span>
          </li>
          <li
            key={`/jobs/${jobPost.id}`}
            className="inline-block mr-2 font-medium text-4xs">
            <Link
              to={`/jobs/${jobPost.id}`}
              className="mr-2 inline-block align-middle hover:underline text-neutral-60 hover:underline-offset-2">
              {jobPost.title}
            </Link>
          </li>
        </ul>
      </div>

      <div className="px-5 sm:px-8 md:px-[60px] w1180:px-0 lg:max-w-[1112px] lg:mx-auto mt-6">
        <div className="bg-neutral-99 p-5 md:p-8 md:flex justify-between rounded-2xl">
          <div className="md:pr-10">
            <div className="flex gap-3 items-center mb-4">
              <div className="rounded border border-neutral-90 h-12 w-12 md:h-16 md:w-16 p-1 bg-white">
                <GatsbyImage
                  image={getImage(jobPost.company.logo as any)!}
                  alt={jobPost.title}
                  loading="eager"
                  objectFit="contain"
                />
              </div>
              <div className="text-base md:text-lg font-semibold leading-[24px]">
                {jobPost.company.name}
              </div>
            </div>

            <h1 className="text-4xl md:text-7xl font-bold leading-[1.375em] mb-5">
              {jobPost.title}
            </h1>

            <div className="flex flex-col md:flex-row gap-2 md:gap-6 mb-8">
              <div className="flex gap-1 items-center">
                <Location />
                <span className="text-xs text-neutral-30 font-regular">
                  {jobPost.locations
                    .map((location) => location.name)
                    .join(" / ")}
                </span>
              </div>

              <div className="flex gap-1 items-center">
                <Organizer />
                <span className="text-xs text-neutral-30 font-regular">
                  {jobPost.employmentTypes.map((type) => type.name).join(" / ")}
                </span>
              </div>

              <div className="flex gap-1 items-center">
                <JPY />
                <span className="text-xs text-neutral-30 font-regular">
                  {!!jobPost.minimumSalary && (
                    <span>{`${jobPost.minimumSalary}万円`}</span>
                  )}

                  {(!!jobPost.minimumSalary || !!jobPost.maximumSalary) && (
                    <span>〜</span>
                  )}

                  {!!jobPost.maximumSalary && (
                    <span>{`${jobPost.maximumSalary}万円`}</span>
                  )}

                  {!jobPost.minimumSalary && !jobPost.maximumSalary && (
                    <span>-</span>
                  )}
                </span>
              </div>
            </div>

            <div className="mb-8">
              <div className="text-2xs text-neutral-60 font-semibold leading-[24px] mb-3">
                使用するツール
              </div>
              <ul className="flex gap-2 flex-wrap">
                {jobPost.tools?.map((tool) => (
                  <li
                    key={tool}
                    className="bg-white text-3xs font-semibold leading-4 text-cyan-strong py-1 px-3 rounded-full border border-cyan-strong">
                    {tool}
                  </li>
                ))}
              </ul>
            </div>

            <div className="mb-8 md:mb-0">
              <div className="text-2xs text-neutral-60 font-semibold leading-[24px] mb-3">
                この求人の特徴
              </div>
              <ul className="flex gap-3 flex-wrap">
                {jobPost.labels.map((label) => (
                  <li
                    key={label.id}
                    className="text-3xs font-semibold leading-4 text-cyan-strong">
                    {`# ${label.name}`}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="md:min-w-[384px] md:py-6 md:pl-10 md:border-l border-neutral-90">
            <div className="mb-8">
              <div className="text-2xs text-neutral-60 font-semibold leading-[24px] mb-3">
                関わるサービス
              </div>

              <a
                href={jobPost.services[0].url!}
                rel="sponsored noopener"
                target="_blank">
                <GatsbyImage
                  image={getImage(jobPost.services[0].images[0] as any)!}
                  alt={jobPost.services[0].name}
                  loading="eager"
                  className="w-[240px] rounded border border-neutral-90-0.6 mb-1"
                />
              </a>

              <a
                href={jobPost.services[0].url!}
                rel="sponsored noopener"
                target="_blank"
                className="block text-neutral-30 underline hover:inline-link break-words underline-offset-2 text-xs leading-6">
                {jobPost.services[0].name}
                <Window
                  color="#2E2E30"
                  className="inline-block mx-[3px]"
                  width={14}
                  height={14}
                />
              </a>
            </div>

            <div>
              <div className="text-2xs text-neutral-60 font-semibold leading-[24px] mb-3">
                関連リンク
              </div>

              <ul>
                {jobPost.company.links.map((link: any) => (
                  <li
                    key={link.id}
                    className="flex gap-3 mb-4 last:mb-0 items-baseline">
                    <div className="text-3xs font-medium text-neutral-60 leading-5 text-center bg-neutral-90 rounded-full w-[56px] shrink-0">
                      {link.type}
                    </div>
                    <a
                      href={link.url}
                      rel="sponsored noopener"
                      target="_blank"
                      className="break-all block text-neutral-30 underline hover:inline-link break-words underline-offset-2 text-2xs md:text-xs leading-5">
                      {link.url}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-10 gap-x-4 sm:gap-x-8 lg:gap-x-[80px]">
          <RichText
            id="content"
            className="col-start-1 col-end-13 w1180:col-start-1 w1180:col-end-8">
            {jobPost.content!}
          </RichText>

          <div className="hidden md:block col-start-8 col-end-11">
            <TableOfContents
              jobPost={jobPost}
              className="shrink-0 flex-1 mt-20 mb-16"
            />

            {jobPost.relatedArticles.length > 0 && (
              <div className="max-w-[768px]">
                <p className="font-extrabold text-4xl text-neutral-90 mb-6">
                  Related Articles
                </p>

                <ul>
                  {jobPost.relatedArticles.map((article) => (
                    <li key={article.id} className="mb-8">
                      <ArticleCard
                        article={article}
                        className="lg:gap-10 xl:gap-10"
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div id="company" className="max-w-[768px] mb-20">
          <div className="mt-8 mb-8">
            <h2 className=" text-3xl font-bold leading-[1.6em]">会社概要</h2>
          </div>

          <dl>
            <div className="flex-col md:flex-row gap-1 md:gap-0 flex mb-8 md:mb-6">
              <dt className="text-neutral-60 font-bold min-w-[200px] leading-8">
                企業名
              </dt>
              <dd className="leading-8">{jobPost.company.name}</dd>
            </div>
            <div className="flex-col md:flex-row gap-1 md:gap-0 flex mb-8 md:mb-6">
              <dt className="text-neutral-60 font-bold min-w-[200px] leading-8">
                設立年月日
              </dt>
              <dd className="leading-8">{jobPost.company.establishmentDate}</dd>
            </div>
            <div className="flex-col md:flex-row gap-1 md:gap-0 flex mb-8 md:mb-6">
              <dt className="text-neutral-60 font-bold min-w-[200px] leading-8">
                本社所在地
              </dt>
              <dd className="leading-8">
                {jobPost.company.headOfficeLocation}
              </dd>
            </div>
            <div className="flex-col md:flex-row gap-1 md:gap-0 flex mb-8 md:mb-6">
              <dt className="text-neutral-60 font-bold min-w-[200px] leading-8">
                従業員数
              </dt>
              <dd className="leading-8">{jobPost.company.numberOfEmployees}</dd>
            </div>
          </dl>
        </div>

        <div id="links" className="max-w-[768px] mb-20">
          <div className="mt-16 mb-8">
            <h2 className=" text-3xl font-bold leading-[1.6em]">{`${jobPost.company.name}での仕事についてもっと知る`}</h2>
          </div>

          {jobPost.links.map((link: any) => (
            <JobPostLink key={link.id} link={link} />
          ))}
        </div>

        {jobPost.relatedArticles.length > 0 && (
          <div id="articles" className="max-w-[768px] mb-20">
            <div className="mt-16 mb-8">
              <h2 className=" text-3xl font-bold leading-[1.6em]">関連記事</h2>
            </div>

            <ul>
              {jobPost.relatedArticles.map((article) => (
                <li key={article.id} className="mb-8">
                  <ArticleItem
                    article={article}
                    className="lg:gap-10 xl:gap-10"
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {jobPost.closed ? (
        <div className="fixed bottom-0 left-0 right-0 bg-neutral-98 z-50">
          <div className="py-5 px-5 sm:px-8 lg:px-[60px] xl:px-0 xl:max-w-[1112px] xl:mx-auto flex justify-center items-center">
            {/* <div className="flex flex-col md:flex-row md:gap-2 md:items-center"> */}
            <div className="font-bold md:leading-8 text-xs md:text-base text-center">
              こちらの求人は募集を終了しています。
            </div>
            {/* </div> */}
          </div>
        </div>
      ) : (
        <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-neutral-90 z-50">
          <div className="py-5 px-5 sm:px-8 lg:px-[60px] xl:px-0 xl:max-w-[1112px] xl:mx-auto flex justify-between items-center gap-5">
            <div className="flex flex-col md:flex-row md:gap-2 md:items-center">
              <div className="font-bold md:leading-8 text-xs md:text-base">
                {jobPost.title}
              </div>
              <div className="text-2xs md:text-xs font-semibold text-neutral-60">
                {jobPost.company.name}
              </div>
            </div>

            <a
              rel="noopener"
              target="_blank"
              href={jobPost.applicationUrl!}
              onClick={(e) => onApplication(e)}
              className="cta shrink-0 text-sm font-bold text-white leading-4 py-4 px-8 sm:px-11 bg-black rounded">
              <span className="hidden sm:inline">この求人に</span>
              <span>応募する</span>
            </a>
          </div>
        </div>
      )}
    </Frame>
  );
};

export default JobPostPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const jobPost = new JobPost(data.jobPost as Queries.ContentfulJobPost);

  page.title = page.title
    .replaceAll("{jobPost.title}", jobPost.title)
    .replaceAll("{jobPost.company.name}", jobPost.company.name);
  page.metaTitle = page.metaTitle
    .replaceAll("{jobPost.title}", jobPost.title)
    .replaceAll("{jobPost.company.name}", jobPost.company.name);
  page.url.pathname = `/jobs/${jobPost.id}/`;
  page.breadcrumb[2].name = page.breadcrumb[2].name
    .replaceAll("{jobPost.title}", jobPost.title)
    .replaceAll("{jobPost.company.name}", jobPost.company.name);
  page.breadcrumb[2].pathname = page.breadcrumb[2].pathname.replaceAll(
    "{jobPost.id}",
    jobPost.id
  );

  const sd = structuredData(page, jobPost);
  return <SEO page={page} sd={sd} />;
};

export const query = graphql`
  query JobPostPage($id: String) {
    page: contentfulPage(url: { pathname: { eq: "/jobs/{jobPost.id}/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
    jobPost: contentfulJobPost(contentful_id: { eq: $id }) {
      ...JobPostFields
    }
  }
`;
