import React, { useState, useEffect, useMemo } from "react";
import { graphql, Link, navigate } from "gatsby";
import Page from "~/models/page";
import SEO from "~/views/components/generic/seo";
import structuredData from "~/helpers/structured-data";
import { useLocation } from "@reach/router";
import Frame from "~/views/layout/frame";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Header from "~/views/compositions/navigation/header";
import Tagline from "~/views/components/generic/tagline";
import PageHeader from "~/views/compositions/generic/header";
import JobPost from "~/models/job-post";
import JobPostCard from "~/views/components/job/job-post-card";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import cx from "classnames";
import { algoliasearch } from "algoliasearch";
import JobPostSearchConditions from "~/models/job-post-search-conditions";
import {
  JobPostCategory,
  JobPostEmploymentType,
  JobPostLabel,
  JobPostLocation,
} from "~/models/job-post";

const hitsPerPage = 15;

type Props = {
  data: Queries.JobListPageQuery;
};

const JobListPage = ({ data }: Props) => {
  const categories = data.categories.nodes.map(
    (category) =>
      new JobPostCategory(category as Queries.ContentfulJobPostCategory)
  );
  const types = data.employmentTypes.nodes.map(
    (type) =>
      new JobPostEmploymentType(type as Queries.ContentfulJobPostEmploymentType)
  );
  const locations = data.locations.nodes.map(
    (location) =>
      new JobPostLocation(location as Queries.ContentfulJobPostLocation)
  );
  const labels = data.labels.nodes.map(
    (label) => new JobPostLabel(label as Queries.ContentfulJobPostLabel)
  );
  const page = new Page(data.page as Queries.ContentfulPage);

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search) as any;
  const params = Object.fromEntries(urlSearchParams.entries());
  const [conditions] = useState(new JobPostSearchConditions(params)) as any;

  const jobPosts = data.jobPosts.nodes.map(
    (jobPost) => new JobPost(jobPost as Queries.ContentfulJobPost)
  );

  const [searchPageInfo, setSearchPageInfo] = useState({
    currentPage: data.jobPosts.pageInfo.currentPage,
    hasNextPage: data.jobPosts.pageInfo.hasNextPage,
    hasPreviousPage: data.jobPosts.pageInfo.hasPreviousPage,
    pageCount: data.jobPosts.pageInfo.pageCount,
  }) as any;

  const [hits, setHits] = useState([]);

  const client = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID!,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY!
      ),
    []
  );

  useEffect(() => {
    if (!conditions.isEmpty) {
      search(conditions);
    }
  }, []);

  const toggleFilter = (field: string, param: any) => {
    conditions.toggle(field, param);

    if (conditions.isEmpty) {
      loadDefaultJobPosts();
      navigate("/jobs/");
      return;
    }

    navigate(`/jobs?${conditions.urlParams()}`);
    search(conditions);
  };

  const loadDefaultJobPosts = async () => {
    try {
      const response = await fetch(`/page-data/jobs/page-data.json`).then(
        (response) => response.json()
      );

      const jobPosts = response.result.data.jobPosts.nodes.map(
        (jobPost: Queries.ContentfulJobPost) => new JobPost(jobPost)
      );

      setHits(jobPosts);
      setSearchPageInfo({
        currentPage: response.result.data.jobPosts.pageInfo.currentPage,
        hasNextPage: response.result.data.jobPosts.pageInfo.pageCount,
        hasPreviousPage: response.result.data.jobPosts.pageInfo.hasPreviousPage,
        pageCount: response.result.data.jobPosts.pageInfo.pageCount,
      });
    } catch (error) {}
  };

  const search = async (conditions: JobPostSearchConditions) => {
    try {
      let result = await client.searchSingleIndex({
        indexName: process.env.GATSBY_ALGOLIA_JOBS_INDEX_NAME!,
        searchParams: {
          facetFilters: conditions.algoliaFacetFilters(),
          page: 0,
          hitsPerPage,
        },
      });

      const hits = result.hits;

      setHits(hits.map((h: any) => new JobPost(h)) as any);

      setSearchPageInfo({
        currentPage: result.page! + 1,
        hasNextPage: result.nbPages! != result.page!,
        hasPreviousPage: result.page! > 1,
        pageCount: result.nbPages!,
      });
    } catch (error) {}
  };

  const posts = hits.length > 0 ? hits : jobPosts;

  return (
    <Frame>
      <Header />
      <Tagline />
      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />
      <PageHeader
        title="デジタルプロダクトデザイナー求人"
        description="プロダクトデザイナーやUXデザイナー、UIデザイナー、UXリサーチャーなどのデジタルプロダクトデザイン関連に特化した求人募集情報を掲載しております。掲載に関するご相談は、お問い合わせフォームよりお願いいたします。"
      />

      <div className="px-5 sm:px-8 lg:px-[60px] xl:px-0 xl:max-w-[1328px] xl:mx-auto flex gap-16 items-start">
        <div className="w-[180px] xl:w-[224px] hidden md:block shrink-0">
          <div className="mb-8">
            <h2 className="font-semibold py-2">職種</h2>
            <ul>
              {categories.map((category) => (
                <li
                  key={category.slug}
                  className="text-xs py-[10px] border-b border-neutral-98 font-medium leading-[20px]">
                  <button
                    className="w-full flex justify-between hover:highlighted-link"
                    onClick={() => toggleFilter("categories", category.slug)}>
                    <span>{category.name}</span>
                    {conditions.has("categories", category.slug) && <Check />}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="mb-8">
            <h2 className="font-semibold py-2">雇用形態</h2>
            <ul>
              {types.map((type) => (
                <li
                  key={type.slug}
                  className="text-xs py-[10px] border-b border-neutral-98 font-medium leading-[20px]">
                  <button
                    className="w-full flex justify-between hover:highlighted-link"
                    onClick={() => toggleFilter("types", type.slug)}>
                    <span>{type.name}</span>
                    {conditions.has("types", type.slug) && <Check />}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="mb-8">
            <h2 className="font-semibold py-2">特徴</h2>
            <ul>
              {labels.map((label) => (
                <li
                  key={label.slug}
                  className="text-xs py-[10px] border-b border-neutral-98 font-medium leading-[20px]">
                  <button
                    className="w-full flex justify-between hover:highlighted-link"
                    onClick={() => toggleFilter("labels", label.slug)}>
                    <span>{label.name}</span>
                    {conditions.has("labels", label.slug) && <Check />}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h2 className="font-semibold py-2">勤務地</h2>
            <ul>
              {locations.map((location) => (
                <li
                  key={location.slug}
                  className="text-xs py-[10px] border-b border-neutral-98 font-medium leading-[20px]">
                  <button
                    className="w-full flex justify-between hover:highlighted-link"
                    onClick={() => toggleFilter("locations", location.slug)}>
                    <span>{location.name}</span>
                    {conditions.has("locations", location.slug) && <Check />}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="w-full">
          <div className="grid gap-x-4 gap-y-5 sm:gap-x-6 sm:gap-y-8 xl:gap-x-10 xl:gap-y-12 grid-cols-1 sm:grid-cols-3">
            {posts.map((post) => (
              <JobPostCard key={post.id} jobPost={post} />
            ))}
          </div>

          <div className="mt-14">
            <ul className="flex gap-1">
              {searchPageInfo.hasPreviousPage && (
                <li key="prev">
                  <Link
                    className="text-xs font-medium leading-10 h-10 w-10 border border-neutral-90 flex justify-center items-center"
                    to={
                      searchPageInfo.currentPage == 2
                        ? "/jobs/"
                        : `/jobs/${searchPageInfo.currentPage - 1}/`
                    }>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10 14L5 9L10 4"
                        stroke="#2E2E30"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                </li>
              )}

              {Array.from({ length: searchPageInfo.pageCount }).map((_, i) => (
                <li key={i}>
                  <Link
                    className={cx(
                      "block text-xs font-medium leading-10 h-10 w-10 text-center",
                      {
                        "bg-neutral-30  text-white":
                          searchPageInfo.currentPage == i + 1,
                        "border border-neutral-90":
                          searchPageInfo.currentPage != i + 1,
                      }
                    )}
                    to={i == 0 ? "/jobs/" : `/jobs/pages/${i + 1}/`}>
                    {i + 1}
                  </Link>
                </li>
              ))}

              {searchPageInfo.hasNextPage && (
                <li key="next">
                  <Link
                    className="text-xs font-medium leading-10 h-10 w-10 border border-neutral-90 flex justify-center items-center"
                    to={`/jobs/pages/${searchPageInfo.currentPage + 1}/`}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6 14L11 9L6 4"
                        stroke="#2E2E30"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>

      <NewsletterSubscription className="mt-[160px]" type="dark" />
    </Frame>
  );
};

export default JobListPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const defaultPageInfo = data.jobPosts.pageInfo;
  const sd = structuredData(page);

  if (defaultPageInfo.currentPage > 1) {
    page.url.pathname = `/jobs/pages/${data.jobPosts.pageInfo.currentPage}/`;
  }

  return (
    <SEO page={page} sd={sd}>
      {defaultPageInfo.hasPreviousPage && (
        <link
          rel="prev"
          href={
            defaultPageInfo.currentPage == 2
              ? `${process.env.GATSBY_SITE_URL}/jobs/`
              : `${process.env.GATSBY_SITE_URL}/jobs/pages/${
                  defaultPageInfo.currentPage - 1
                }/`
          }
        />
      )}

      {defaultPageInfo.hasNextPage && (
        <link
          rel="next"
          href={`${process.env.GATSBY_SITE_URL}/jobs/pages/${
            defaultPageInfo.currentPage + 1
          }/`}
        />
      )}
    </SEO>
  );
};

const Check = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#7DC1D2" />
      <path
        d="M5 10.5C5 10.5 7.22222 13.0278 8.33333 14L15 7"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const query = graphql`
  query JobListPage($skip: Int, $limit: Int) {
    page: contentfulPage(url: { pathname: { eq: "/jobs/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
    categories: allContentfulJobPostCategory(
      filter: { slug: { ne: "service-designer" } }
    ) {
      nodes {
        ...JobPostCategoryFields
      }
    }
    employmentTypes: allContentfulJobPostEmploymentType {
      nodes {
        ...JobPostEmploymentTypeFields
      }
    }
    locations: allContentfulJobPostLocation {
      nodes {
        ...JobPostLocationFields
      }
    }
    labels: allContentfulJobPostLabel {
      nodes {
        ...JobPostLabelFields
      }
    }
    jobPosts: allContentfulJobPost(
      sort: { createdAt: DESC }
      skip: $skip
      limit: $limit
      filter: { closed: { ne: true } }
    ) {
      nodes {
        ...JobPostFields
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`;
