import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from "gatsby-source-contentful/rich-text";
import Company from "./company";
import JobPostService from "./job-post-service";
import Article from "./article";
import News from "./news";

export default class JobPost {
  id: string;
  title: string;
  company: Company;
  categories: JobPostCategory[];
  services: JobPostService[];
  labels: JobPostLabel[];
  employmentTypes: JobPostEmploymentType[];
  locations: JobPostLocation[];
  minimumSalary: number | null;
  maximumSalary: number | null;
  tools: string[] | null;
  content: RenderRichTextData<ContentfulRichTextGatsbyReference> | null;
  links: any;
  relatedArticles: Article[];
  applicationUrl: string | null;
  closed: boolean;

  constructor(jobPost: Queries.ContentfulJobPost) {
    this.id = jobPost.contentful_id || jobPost.id;
    this.title = jobPost.title ?? "";
    this.company = new Company(jobPost.company!);
    this.categories = jobPost.categories
      ? jobPost.categories.map((category) => new JobPostCategory(category!))
      : [];
    this.locations = jobPost.locations
      ? jobPost.locations.map((location) => new JobPostLocation(location!))
      : [];
    this.employmentTypes = jobPost.employmentTypes
      ? jobPost.employmentTypes.map((type) => new JobPostEmploymentType(type!))
      : [];
    this.services = jobPost.services
      ? jobPost.services.map((service) => new JobPostService(service!))
      : [];
    this.labels = jobPost.labels
      ? jobPost.labels.map((label) => new JobPostLabel(label!))
      : [];
    this.minimumSalary = jobPost.minimumSalary ?? null;
    this.maximumSalary = jobPost.maximumSalary ?? null;
    this.tools = jobPost.tools?.map((tool) => tool!) ?? null;
    this.applicationUrl = jobPost.applicationUrl ?? null;
    this.content = jobPost.content as any;
    this.links = jobPost.links ?? [];
    this.relatedArticles =
      jobPost.relatedArticles?.map((article: any) => new Article(article)) ??
      [];
    this.closed = jobPost.closed || false;
  }
}

export class JobPostLabel {
  id: string;
  name: string;
  slug: string;

  constructor(label: Queries.ContentfulJobPostLabel) {
    this.id = label.id;
    this.name = label.name!;
    this.slug = label.slug!;
  }
}

export class JobPostCategory {
  id: string;
  name: string;
  slug: string;

  constructor(label: Queries.ContentfulJobPostCategory) {
    this.id = label.id;
    this.name = label.name!;
    this.slug = label.slug!;
  }
}

export class JobPostLocation {
  id: string;
  name: string;
  slug: string;

  constructor(label: Queries.ContentfulJobPostLocation) {
    this.id = label.id;
    this.name = label.name!;
    this.slug = label.slug!;
  }
}

export class JobPostEmploymentType {
  id: string;
  name: string;
  slug: string;

  constructor(label: Queries.ContentfulJobPostEmploymentType) {
    this.id = label.id;
    this.name = label.name!;
    this.slug = label.slug!;
  }
}
